<template>
  <div>
    <CSBreadcrumb />
    <div class="filter-panel">
      <CSSelect style="width: 210px">
        <el-date-picker
          v-model="searchTerm.startTime"
          type="datetime"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          placeholder="请选择开始时间"
          :editable="false"
        ></el-date-picker>
      </CSSelect>
      <span
        style="
          display: inline-block;
          vertical-align: middle;
          margin: 0px 20px 15px 0px;
          height: 2px;
          width: 20px;
          background-color: #999;
        "
      ></span>
      <CSSelect style="width: 210px">
        <el-date-picker
          v-model="searchTerm.endTime"
          type="datetime"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          placeholder="请选择结束时间"
          :editable="false"
        ></el-date-picker>
      </CSSelect>
      <button
        type="button"
        @click="getFaceData()"
        class="btn btn-primary btn-sm"
      >
        查询
      </button>
    </div>

    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:thead>
          <tr>
            <th>抓拍时间</th>
            <th>姓名</th>
            <th>身份</th>
            <th>抓拍人脸图像</th>
            <th>是否关注人</th>
            <th>监控/闸机/门禁</th>
            <th>位置</th>
            <th>档案</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="face in faceCaptureList" :key="face.id + 'a'">
            <td class="date-td">{{ face.createTime || "-" }}</td>
            <td>{{ face.name || "-" }}</td>
            <td>{{ identifyTypes[face.identity] || "否" }}</td>
            <td style="min-width: 200px">
              <img
                v-if="face.faceUrl"
                @click="viewBigImg(face.faceUrl, '查看人脸')"
                :src="face.faceUrl"
                width="80px"
                height="80px"
                style="cursor: pointer"
              />
              <img
                v-if="face.panorama"
                @click="viewBigImg(face.panorama, '查看全景')"
                :src="face.panorama"
                width="80px"
                height="80px"
                style="cursor: pointer; margin-left: 10px"
              />
            </td>
            <td>{{ subReasonList[face.isAttention] || "-" }}</td>
            <td>
              <span
                v-if="face.numbering"
                :class="{ 'allow-click': face.type === 1 }"
                @click="checkCameraDetail(face.cameraId)"
              >
                {{ face.numbering }}
              </span>
              <span v-else>-</span>
            </td>
            <td>{{ face.specificLocation || "-" }}</td>
            <td>
              <span class="allow-click" @click="viewRecord(face.faceDataId)">
                查看
              </span>
            </td>
          </tr>
        </template>
      </CSTable>

      <Pagination name="Pagination" componentName="Pagination"></Pagination>
    </div>
    <ViewImageModal />
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import ViewImageModal from "@/components/ViewImageModal";
import { queryCapturePhotoLogUrl } from "@/requestUrl";
import dayjs from "dayjs";
import Pagination from "@/components/Pagination";
import CSTable from "@/components/common/CSTable";

export default {
  name: "capturePhotoLog",
  props: {
    faceId: Number,
  },
  components: {
    CSTable,
    CSSelect,
    ViewImageModal,
    CSBreadcrumb,
    Pagination,
  },
  data() {
    return {
      filterHeight: 0,
      searchTerm: {
        regionCode: this.$vc.getCurrentRegion().code, //区域编码
        startTime: dayjs().subtract(7, "days").format("YYYY-MM-DD 00:00"), //开始时间
        endTime: dayjs().format("YYYY-MM-DD 23:59"), //结束时间
      },
      faceCaptureList: [],
    };
  },
  async mounted() {
    this.getFaceData();
    this.$vc.on(this.$route.path, "pagination_page", "event", (page) => {
      this.getFaceData(page);
    });
  },
  beforeDestroy() {},
  deactivated() {},
  methods: {
    getFaceData(pageNo = 1, pageSize = 10) {
      this.$fly
        .post(queryCapturePhotoLogUrl, {
          pageNo,
          pageSize,
          faceId: this.faceId,
          ...this.searchTerm,
        })
        .then((res) => {
          this.$vc.emit(this.$route.path, "pagination", "init", {
            total: res.data.total,
            currentPage: pageNo,
            pageSize,
          });
          this.faceCaptureList = res.data.datas;
          setTimeout(() => {
            this.$vc.emit("loading", {});
          }, 3000);
        });
    },
  },
};
</script>

<style scoped>
</style>
